<template>
  <div class="pa-3">
    <div class="d-flex justify-center">
      <div class="col-sm-12 col-md-5 col-lg-3 d-flex flex-column">
        <h2>PLANOS FERIA</h2>
      </div>
    </div>
    <div class="d-flex justify-center">
      <div class="col-sm-12 col-md-5 col-lg-3">
        <v-btn
          v-for="(plan, i) in plans"
          :key="i"
          v-text="plan.name"
          :color="plan.appBtnColor"
          class="my-2 elevation-3 w-100"
          :style="`color: ${plan.appBtnTextColor};`"
          @click="redirectToPlan(plan)"
        ></v-btn>
      </div>
    </div>
  </div>
</template>

<style scoped>
.w-100 {
  min-width: 100% !important;
  width: 100% !important;
}
</style>

<script>
import loaderMixin from "@/mixins/loaderMixin";

export default {
  name: "plans-fair",
  mixins: [loaderMixin],
  data() {
    return {
      plans: [],
    };
  },
  methods: {
    redirectToPlan(plan) {
      this.$store.commit("plans/setPlan", plan);
      this.$router.push({ name: "plan-fair", params: { planId: plan.id } });
    },
    getPlansFair() {
      this.$axiosApiMobile
        .get("/places?fairId=" + this.fair?.id)
        .then((res) => {
          this.plans = res.data;
        });
    },
  },
  computed: {
    fair() {
      return this.$store.getters["fairs/fair"];
    },
  },
  created() {
    this.getPlansFair();
  },
};
</script>
